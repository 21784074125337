var GEESD = GEE;

GEESD.options.dataProductId = 'geeVariant';
GEESD.sessionObject.impression.product['cart-accessories'] = [];
GEESD.sessionObject.impression.product['alternative-product'] = [];

/**
* Specialni rozsireni funkce pro Software Deals, nektere produtky jsou
* totiz zanorene skryty, a puvodni funkce je oznacuje jako viditelne,
* coz ma za nasledek zalogovani zobrazeni produktu a nasledne odeslani
* do GTM.
*/
GEESD.isInViewport = function(element) {
    if ('none' == getComputedStyle(element.parentElement.parentElement.parentElement, null).display) {
        return false;
    } else {
        var bounding = element.getBoundingClientRect();
        return (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
};

/**
* Pretizeni funkce pro zaznamenani vlozeni produktu do kosiku. Projekt
* Software Deals ma tlacitka pro pridani do kosiku i na produktovych kartach,
* a sdileji stejnou tridu jako tlacitko v detailu produktu.
*/
GEESD.bindCartAdd = function() {
    var buyButtons = document.querySelectorAll('.' + GEESD.options.cardBuyClass);
    var addedAccessories = [];
    if (buyButtons.length) {
        for (var b = 0; b < buyButtons.length; b++) {
            buyButtons[b].addEventListener('click', function() {
                if (!this.classList.contains('on-request') && !this.classList.contains('no-gtm')) {
                    var variantId = this.dataset.idVariant;
                    if (document.GEEItems[variantId]) {
                        var item = document.GEEItems[variantId];
                        var productCard = GEESD.findAncestor(this, '.' + GEESD.options.productClass);
                        var additionalData = null;
                        item.quantity = parseInt(this.dataset.count);
                        if (this.closest('.compare-products') || this.closest('.js-product-card.has-alternative-product')) {
                            additionalData = { list: 'alternative-product' };
                        } else if (document.body.classList.contains(GEESD.options.detailClass)) {
                            var accessories = document.querySelectorAll('.js-product-accessory');
                            additionalData = { list: 'product-detail' };
                            // V detailu produktu muzou byt nabizeny prislusenstvi, ktere se trackuje zvlast
                            if (accessories.length) {
                                for (var i = 0; i < accessories.length; i++) {
                                    if (accessories[i].checked) {
                                        if (document.GEEItems[accessories[i].dataset.variantId]) {
                                            var accessoryItem = document.GEEItems[accessories[i].dataset.variantId];
                                            accessoryItem.quantity = item.quantity;
                                            addedAccessories.push(accessoryItem);
                                        } else {
                                            GEESD.log('Prislusenstvi s ID varianty ' + accessories[i].dataset.variantId + ' nebylo zaznamenano, protoze chybi jeho data.');
                                        }
                                    }
                                }
                            }
                        } else if (productCard) {
                            // Pokud byl produkt pridan z karty, je treba posilat i zdroj (nazev seznamu)
                            additionalData = { list: productCard.dataset[GEESD.options.dataProductList] };
                        }
                        // Odstraneni prebytecne vlastnosti
                        delete item.position;
                        GEESD.push('add', [item], additionalData);
                        if (addedAccessories.length) {
                            GEESD.push('add', addedAccessories, { list: 'accessories' });
                        }
                    } else {
                        GEESD.log('Vlozeni do kosiku nebylo zaznamenano. Chybi data o variante s ID ' + variantId + '.');
                    }
                }
            }); 
        }
    }
};

GEESD.bindCartRemove = function() {
    var removeButtons = document.querySelectorAll('.' + GEESD.options.cartRemoveClass);
    if (removeButtons.length) {
        for (var b = 0; b < removeButtons.length; b++) {
            removeButtons[b].addEventListener('click', function() {
                var variantId = this.dataset.idVariant;
                if (document.GEEItems[variantId]) {
                    var item = document.GEEItems[variantId];
                    var additionalData = null;
                    item.quantity = parseInt(this.dataset.count);
                    if (this.classList.contains('js-cartbox__deleteitem')) {
                        additionalData = { list: 'cartbox' };
                    } else if (this.dataset.list) {
                        additionalData = { list: 'cart-accessories' };
                    } else {
                        additionalData = { list: 'cart' };
                    }
                    GEESD.push('remove', [item], additionalData);
                } else {
                    GEESD.log('Odstraneni z kosiku nebylo zaznamenano. Chybi data o variante s ID ' + variantId + '.');
                }
            }); 
        }
    }
};

GEESD.loadSession = function() {
    if (GEESD.isSessionAvailable && sessionStorage.getItem(GEESD.options.sessionStorageName)) {
        var sessionObject = JSON.parse(sessionStorage.getItem(GEESD.options.sessionStorageName));
        GEESD.sessionObject.loginAttempt = sessionObject.loginAttempt;
        GEESD.sessionObject.user = sessionObject.user;
    }
};

/**
* Pokus o ulozeni karty produktu do relacni promenne, a nasledne odeslani.
*/
GEESD.logProduct = function(product) {
    // Pokud polozka je viditelna a neni jeste oznackovana
    if (GEESD.isInViewport(product) && !product.dataset[GEESD.options.dataSeenTag]) {
        var list = product.dataset[GEESD.options.dataProductList];
        var id = parseInt(product.dataset[GEESD.options.dataProductId]);
        // Pokud existuje seznam v relacni promenne a zaznam v nem jeste neni
        if (GEESD.sessionObject.impression.product[list] && GEESD.sessionObject.impression.product[list].indexOf(id) < 0) {
            GEESD.sessionObject.impression.product[list].push(id);
            product.dataset[GEESD.options.dataSeenTag] = true;
            GEESD.log('Product s ID ' + id + ' pridan do seznamu "' + list + '".');
            if (product.dataset.geeAlternativeId) {
                GEESD.sessionObject.impression.product['alternative-product'].push(parseInt(product.dataset.geeAlternativeId));
            }
            return true;
        }
    }
    return false;
};

/**
* Akce provadene pri prohlizeni detailu produktu.
*/
GEESD.bindDetailView = function() {
    if (!document.GEEItems) {
        GEESD.log('Objekt "GEEItems" nebyl v dokumentu nalezen. Chybi data o produktu.');
    } else {     
        var viewText = 'poněkolikáté.';
        if (-1 == GEESD.sessionObject.impression.product['product-detail'].indexOf(document.productDetail.id)) {
            GEESD.sessionObject.impression.product['product-detail'].push(document.productDetail.id);
            if (document.productDetail.alternativeId) {
                GEESD.sessionObject.impression.product['alternative-product'].push(document.productDetail.alternativeId);
            }
            GEESD.saveSession();
            viewText = 'poprvé.';
        }
        GEESD.push('productDetail', [document.GEEItems[document.productDetail.variantId]]);
        GEESD.log('Detail produktu s ID ' + document.productDetail.id + ' byl zobrazen ' + viewText);
    }
};

GEESD.init();