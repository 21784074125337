'use strict';

//zkopirovane zo starter-shopu z babel.js
// Vanilla
// hasClass, addClass, removeClass, toggleClass;
// ===================================================================================



var hasClass, addClass, removeClass, toggleClass;

if ('classList' in document.documentElement) {
    hasClass = function(el, className) {
        if (el !== null) {
            return el.classList.contains(className);
        }
    };
    addClass = function(el, className) {
        if (el !== null) {
            el.classList.add(className);
        }
    };
    removeClass = function(el, className) {
        if (el !== null) {
            el.classList.remove(className);
        }
    };
    toggleClass = function(el, className) {
        if (el !== null) {
            el.classList.toggle(className);
        }
    };
} else {
    hasClass = function(el, className) {
        return new RegExp('\\b' + className + '\\b').test(el.className);
    };
    addClass = function(el, className) {
        if (!hasClass(el, className)) {
            el.className += ' ' + className;
        }
    };
    removeClass = function(el, className) {
        el.className = el.className.replace(new RegExp('\\b' + className + '\\b', 'g'), '');
    };

    toggleClass = function(el, className) {
        var classes = el.className.split(' ');
        var existingIndex = classes.indexOf(className);

        if (existingIndex >= 0)
            classes.splice(existingIndex, 1);
        else
            classes.push(className);

        el.className = classes.join(' ');
    };
}

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) { descriptor.writable = true; } Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) { defineProperties(Constructor.prototype, protoProps); } if (staticProps) { defineProperties(Constructor, staticProps); } return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/** 
 * https://gomakethings.com/climbing-up-and-down-the-dom-tree-with-vanilla-javascript/
 * 
 * Get the closest matching element up the DOM tree.
 * @private
 * @param  {Element} elem     Starting element
 * @param  {String}  selector Selector to match against
 * @return {Boolean|Element}  Returns null if not match found
 */
var getClosest = function getClosest(elem, selector) {

    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector || Element.prototype.oMatchesSelector || Element.prototype.webkitMatchesSelector || function (s) {
            var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                i = matches.length;
            while (--i >= 0 && matches.item(i) !== this) {}
            return i > -1;
        };
    }

    // Get closest match
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) {
            return elem;
        }
    }

    return null;
};

var Templates = function () {
    function Templates() {
        _classCallCheck(this, Templates);
    }

    _createClass(Templates, null, [{
        key: 'rssArticle',
        value: function rssArticle(link, imgLink, title, text) {
            // Příklad šablony
            return '\n\t\t\t<li class="c-rss__item">\n\t\t\t\t<a href="' + link + '" class="c-rss__link">\n\t\t\t\t\t<div class="c-rss__img">\t\n\t\t\t\t\t\t<img src="' + imgLink + '" alt="' + title + '" />\t\t\t\n\t\t\t\t\t</div>\n\t\t\t\t\t<h4 class="c-rss__title">\n\t\t\t\t\t\t' + title + '\n\t\t\t\t\t</h4>\n\t\t\t\t\t<p class="c-rss__text">\t\n\t\t\t\t\t\t' + text + '\n\t\t\t\t\t</p>\n\t\t\t\t\t<span class="btn btn--default">\n\t\t\t\t\t\t' + document.myTranslate.read + '\n\t\t\t\t\t</span>\n\t\t\t\t</a>\n\t\t\t</li>\n\t\t';
        }
    }, {
        key: 'rssArticleList',
        value: function rssArticleList(articlesToRender) {
            return '\n\t\t\t<ul class="c-rss__list">\n\t\t\t\t' + articlesToRender + '\n\t\t\t</ul>\n\t\t\t';
        }
    }]);

    return Templates;
}();

var _filter = Array.prototype.filter;

var MenuTabs = function () {
    function MenuTabs(wrapEl, menuButtonSelector, contentBoxSelector) {
        _classCallCheck(this, MenuTabs);

        // Argument wrapEl musí být jeden element (typ objekt), nikoliv pole více objektů 
        if (wrapEl.length > 1) {
            return false;
        }

        // Promenne tridy
        this.$wrapEl = wrapEl;
        this.menuButtonSelector = menuButtonSelector;
        this.contentBoxSelector = contentBoxSelector;

        this.active = 0;
        this.activeClass = 'is-active';

        this.idString = wrapEl.getAttribute('id');
        this.menuClass = 'section-menu-tabs';
        this.contentClass = 'section-tabs-content';

        // Options
        this.options = {
            // Funkčnost: Closable (.js-tabs data-tabs-closable="")
            // TRUE: Po kliknutí na aktivní záložku se záložka skryje a nebude zobrazena žádná
            // FALSE (default): Vždy je zobrazena alespoň jedna záložka
            closable: false
        };

        // Init
        this.setOptions();
        this.addDataAttributes();
        this.addClickEvents();
        this.setActive(this.active);
    }

    _createClass(MenuTabs, [{
        key: 'setOptions',
        value: function setOptions() {
            // Closable
            var closable = this.$wrapEl.getAttribute('data-tabs-closable');

            if (closable == 'true') {
                this.options.closable = true;
            }
        }
    }, {
        key: 'addDataAttributes',
        value: function addDataAttributes() {
            var self = this;
            // Všem položkám menu přidá atribut data-tab="i", kde i je pořadí položky v menu
            var $menuEl = this.$wrapEl.querySelector('.' + this.menuClass);

            var _a = $menuEl.querySelectorAll(this.menuButtonSelector);

            var _f = function _f(elem, index) {
                elem.setAttribute('data-tab-id', self.idString);
                elem.setAttribute('data-tab-target', index + 1);
            };

            for (var _i = 0; _i < _a.length; _i++) {
                _f(_a[_i], _i, _a);
            }

            undefined;

            // Všem content boxům přidá atribut data-tab="i", kde i je pořadí položky ve wrapperu
            // this.$wrapEl.querySelectorAll(this.contentBoxSelector).forEach(function(elem, index){

            var _a2 = this.$wrapEl.querySelectorAll('#' + this.idString + ' > .' + this.contentClass + ' > ' + this.contentBoxSelector);

            var _f2 = function _f2(elem, index) {
                elem.setAttribute('data-tab-id', self.idString);
                elem.setAttribute('data-tab-target', index + 1);
            };

            for (var _i2 = 0; _i2 < _a2.length; _i2++) {
                _f2(_a2[_i2], _i2, _a2);
            }

            undefined;
        }
    }, {
        key: 'addClickEvents',
        value: function addClickEvents() {
            var _a3 = document.querySelectorAll('#' + this.idString + ' > .' + this.menuClass + ' ' + this.menuButtonSelector);

            var _f3 = function _f3(elem, index) {
                elem.addEventListener("click", function (e) {
                    //e.preventDefault();
                    e.stopPropagation();
                    var tabEl = getClosest(this, '.js-tabs');

                    var instance = tabs.get(tabEl.getAttribute('id'));

                    instance.setActive(this.getAttribute('data-tab-target'));
                    return false;
                });
            };

            for (var _i3 = 0; _i3 < _a3.length; _i3++) {
                _f3(_a3[_i3], _i3, _a3);
            }

            // self = this; // NEFUNGUJE ! Při iteraci se přepisuje, takže při spuštění eventu se použije poslední iterace

            // Všem položkám menu přidá event na click
            undefined;
        }
    }, {
        key: 'setActive',
        value: function setActive() {

            $('.section-tabs-content__item.is-active').trigger('heightChange');

            var activeTarget = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.active;

            var self = this;
            /*$('html, body').animate({
                scrollTop: $('.section-tabs').offset().top - 100
            }, 500);*/


            // Při inicializaci zjistíme, jestli nějaký element z menu náhodou nemá třídu .is-active a případně ji použijeme
            if (activeTarget == 0) {
                var $menuEl = this.$wrapEl.querySelector('.' + this.menuClass);

                var $menuButtonActiveInit = $menuEl.querySelector(this.menuButtonSelector + '.' + this.activeClass);

                if ($menuButtonActiveInit !== null) {

                    // Existuje element v menu s třídou .is-active, takže aktivní přenastavíme podle toho
                    activeTarget = $menuButtonActiveInit.getAttribute('data-tab-target');
                } else {
                    // Aktivní bude první záložka (default)
                    activeTarget = '1';
                }
            }

            setTimeout(function(){
                $("*[data-tab-target=" + this.active + "]").find('.page-slider').slick('refresh');
            }, 300)

            var activeId = this.idString;

            // Funčknost: Closable
            // Pokud uživatel kliknul na aktivní záložku a zároveň je povoleno skrytí všech
            var canCloseAllTabs = this.active == activeTarget && this.options.closable == true;

            // Menu
            // ----------------------------------------------------------------------

            // Odebrání aktivní třídy
            var $menuButtonActiveOld = this.$wrapEl.querySelectorAll(this.menuButtonSelector + '.' + this.activeClass + '[data-tab-id="' + activeId + '"]');    
            _filter.call($menuButtonActiveOld, function (item) {
                removeClass(item, self.activeClass);
            });

            // Pokud skrýváme všechny záložky, odebereme :active z celého menu (jinak zůstane na posledním kliknutém elementu v menu)
            if (canCloseAllTabs) {
                var _a4 = $menuButtonActiveOld;

                var _f4 = function _f4(elem, index) {
                    elem.blur();
                };

                for (var _i4 = 0; _i4 < _a4.length; _i4++) {
                    _f4(_a4[_i4], _i4, _a4);
                }

                $('.section-tabs-content__item.is-active').trigger('heightChange');

                undefined;
            }

            // Pokud uživatel kliknul na aktivní záložku a zároveň je povoleno skrytí všech záložek, nepokračujeme
            if (!canCloseAllTabs) {
                // Přidání aktivní třídy      
                var $menuButtonActiveNew = this.$wrapEl.querySelector(this.menuButtonSelector + '[data-tab-id="' + activeId + '"][data-tab-target="' + activeTarget + '"]'); 
                addClass($menuButtonActiveNew, this.activeClass);
                $menuButtonActiveNew.focus();
            }

            // Content
            // ----------------------------------------------------------------------

            // Odebrání aktivní třídy
            var $contentBoxActiveOld = this.$wrapEl.querySelectorAll(this.contentBoxSelector + '.' + this.activeClass + '[data-tab-id="' + activeId + '"]');    
            _filter.call($contentBoxActiveOld, function (item) {
                removeClass(item, self.activeClass);
            });

            // Pokud uživatel kliknul na aktivní záložku a zároveň je povoleno skrytí všech záložek, nepokračujeme
            if (!canCloseAllTabs) {
                // Přidání aktivní třídy
                var $contentBoxActiveNew = this.$wrapEl.querySelector(this.contentBoxSelector + '[data-tab-id="' + activeId + '"][data-tab-target="' + activeTarget + '"]');
                addClass($contentBoxActiveNew, this.activeClass);

                // Při změně výšky (různé togglery apod uvnitř tabů) přepočítej
                if ($contentBoxActiveNew !== null) {
                    var self = this;

                    // Pro událost onresize se využívá knihovna any-resize-event.js, která je umístěna v essentials/afterDOM 
                    // Na event se navazuje anonymní funkce, která nejde odebrat - šlo by přepsat na externí funkci a přidat removeEventListener
                    $contentBoxActiveNew.addEventListener("onresize", function () {
                        self.setHeight();
                    });
                }
            }

            // Přenastavení tabů
            // ----------------------------------------------------------------------
            if (canCloseAllTabs) {
                // Všechny záložky jsme zavřeli, nastavujeme aktivní na 0 
                this.active = 0;
            } else {
                // Aktivní nastavujeme na nově změněnou aktivní
                this.active = activeTarget;
            }

            this.setHeight();


            // Zjistí, jestli je objekt TAB vnořen do jiného, a přepočítá výšku i nadřazeného
            // ----------------------------------------------------------------------

            // Procházení nodelistů směrem nahoru (od tabového elementu)
            var allParents = [];
            for (var p = this.$wrapEl && this.$wrapEl.parentElement; p; p = p.parentElement) {
                allParents.push(p);
            }

            // Pokud v allParents najdeme element s třídou pro taby, je to vnořený tab
            var parentTabEl = allParents.filter(function (el) {
                return el.classList.contains('js-tabs');
            });

            if (parentTabEl.length > 0) {
                var _a5 = parentTabEl;

                var _f5 = function _f5(elem, index) {
                    if (tabs instanceof MenuTabsContainer) {
                        var instance = tabs.get(elem.getAttribute('id'));

                        // Musi tu byt zpozdeni, protoze jinak se offsetHeight ve funkci setHeight() spočítá špatně a musí se kliknout znova.
                        setTimeout(function () {

                            instance.setHeight();
                        }, 100);
                    }
                };

                for (var _i5 = 0; _i5 < _a5.length; _i5++) {
                    _f5(_a5[_i5], _i5, _a5);
                }

                $('.section-tabs-content__item.is-active').trigger('heightChange');

                undefined;
            }

            $('.section-tabs-content__item.is-active').trigger('heightChange');
        }
    }, {
        key: 'setHeight',
        value: function setHeight() {
            $('.section-tabs-content__item.is-active').trigger('heightChange');
            return;
            var self = this;
            var activeId = this.idString;
            var activeTarget = this.active;

            var $contentBoxActive = document.querySelector('#' + this.idString + ' > .' + this.contentClass + ' ' + this.contentBoxSelector + '[data-tab-id="' + activeId + '"][data-tab-target="' + activeTarget + '"]');


            // Menu nemá tab s obsahem, nastaví se height: auto;
            var newHeight = void 0;
            if ($contentBoxActive === null) {
                newHeight = "auto";
            }

            // ak je sucastou tabov slick slideshow s adaptivnou vyskou, tak musim nastavit vysku podla najvacsieho
            else if ($contentBoxActive.querySelector('.slick-slide') != null) {
                var $slides = $contentBoxActive.querySelectorAll('.slick-slide');
                var $slideActive = $contentBoxActive.querySelector('.slick-active'); //aktivny, ten ten, podla ktoreho vypocita $contentBoxActive.offsetHeight
                newHeight = $contentBoxActive.offsetHeight;

                var heightOfFirst = $slideActive.offsetHeight;
                var biggestHeight = 0;
                for (var i = 0, len = $slides.length; i < len; i++) {
                    biggestHeight = ($slides[i].offsetHeight) > biggestHeight ? $slides[i].offsetHeight : biggestHeight; //najdem najvacsi slide
                }
                var diffHeight = (biggestHeight > heightOfFirst) ? biggestHeight - heightOfFirst : 0; // rozdiel aktivneho a najvacsieho
                newHeight += diffHeight;
                newHeight = String(newHeight) + "px";
            }
            // Nastaví se výška podle tabu
            else {
                    newHeight = String($contentBoxActive.offsetHeight) + "px";
                }

            // Ověříme, že taby mají část s contentem (můžou být jen menu-tabs)
            var $contentEl = document.querySelector('#' + this.idString + ' > .' + this.contentClass);
            if ($contentEl !== null) {
                $contentEl.style.height = newHeight;
            }

            // Kontroluje jestli je vyska ok
            /*setTimeout(function(){
                if ($contentEl !== null) {
                    if ($contentEl.style.height != newHeight) {
                        self.setHeight();
                    }
                }
            }, 500);*/
        }
    }]);

    return MenuTabs;
}();

var MenuTabsContainer = function () {
    function MenuTabsContainer() {
        _classCallCheck(this, MenuTabsContainer);


        // Promenne tridy
        this.items = [];

        // Inituje automaticky všechny taby
        this.initDefault();
    }

    _createClass(MenuTabsContainer, [{
        key: 'create',
        value: function create(wrapEl, menuButtonSelector, contentBoxSelector) {

            // Zjisti atribut ID elementu
            idString = wrapEl.getAttribute('id');

            if (idString === null) {
                // Element nemá atribut ID, vytvoř ho 

                // Vytvoření ID. První objekt má id = tabs0. 
                // Nelze použít array.length, protože to funguje jen pro číselné klíče. Pole this.items má jako klíč string, proto je brán jako objekt, length je vždy 0, nutné použít Object.keys
                var idString = 'tabs' + Object.keys(this.items).length;

                // Přidání ID
                wrapEl.setAttribute('id', idString);
            }

            // Pokud už má element na sobě inicializovánu třídu Tabs, nic neprováděj
            if (!this.isInited(idString)) {

                // Nová instance třídy Tabs
                var instance = new MenuTabs(wrapEl, menuButtonSelector, contentBoxSelector);

                // Přidání do vlastního pole
                this.items[idString] = instance;
            }
        }
    }, {
        key: 'isInited',
        value: function isInited(idString) {
            // Vrací TRUE pokud už je element s daným ID inicializován jako Tabs
            if (this.items[idString] !== undefined) {
                return true;
            } else {
                return false;
            }
        }
    }, {
        key: 'initDefault',
        value: function initDefault() {
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {

                var self = this;
                $('.js-tabs').each(function(){
                    self.create($(this)[0], '.js-tabs__button', '.js-tabs__content');
                });

                /*for (var _iterator = document.querySelectorAll('.js-tabs')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var elem = _step.value;

                    this.create(elem, '.js-tabs__button', '.js-tabs__content');
                }*/

                // Přepočítá výšku tabu při změně rozměrů obrazovky
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            var self = this;
            window.addEventListener('resize', function (e) {

                for (var idString in self.items) {
                    self.items[idString].setHeight();
                }
            });

            // Zpracuj anchor při onload
            self.setActiveByUrlHash();

            // Změna anchoru na stejné stránce (kliknutí na odkaz s kotvou apod.)
            window.addEventListener("hashchange", function (e) {
                self.setActiveByUrlHash();
            });
        }
    }, {
        key: 'setActiveByUrlHash',
        value: function setActiveByUrlHash() {
            // Část URL za znakem #
            var hashString = window.location.hash.replace('#', ''); // kvuli ruznym prohlizecum

            // URL obsahuje anchor
            if (hashString.length > 0) {
                // Najdi tab, ke kterému patří
                var $anchorLinkEl = document.querySelector('a[href="#' + hashString + '"]');

                if ($anchorLinkEl !== null) {
                    // Existuje link, který na tab odkazuje => pokud je to tab, aktivuj ho
                    var idAttr = $anchorLinkEl.getAttribute('data-tab-id');
                    var targetAttr = $anchorLinkEl.getAttribute('data-tab-target');

                    if (idAttr !== null && targetAttr !== null) {
                        // Je to záložka, můžeme aktivovat
                        this.items[idAttr].setActive(targetAttr);
                    }
                }

                // window.location.hash = hashString;
            } else {
                // Najdi tab, ke kterému patří
                var $anchorLinkEl = document.querySelector('a.js-tabs__button');

                if ($anchorLinkEl !== null) {
                    // Existuje link, který na tab odkazuje => pokud je to tab, aktivuj ho
                    var idAttr = $anchorLinkEl.getAttribute('data-tab-id');
                    var targetAttr = $anchorLinkEl.getAttribute('data-tab-target');

                    if (idAttr !== null && targetAttr !== null) {
                        // Je to záložka, můžeme aktivovat
                        this.items[idAttr].setActive(targetAttr);
                    }
                }

            }
        }
    }, {
        key: 'get',
        value: function get(idString) {
            // Vrací instanci Tabs, se kterou je možné dále pracovat
            return this.items[idString];
        }
    }]);

    return MenuTabsContainer;
}();

$('.section-tabs-content__item').bind('heightChange', function() {
    var $this = $(this);
    var height = 0;
    if ($this.hasClass('is-active')) {
        var $parent = $this.parent('.section-tabs-content');
        height = $this.outerHeight(true);
        $parent.css('height', height + 'px');
        setTimeout(function() {
            height = $this.outerHeight(true);
            $parent.css('height', height + 'px');
        }, 200);
    }
});

document.addEventListener('DOMContentLoaded', function(){
   $('.section-tabs-content__item.is-active').trigger('heightChange');
});

if (!document.documentElement.classList.contains('export')) {
    var tabs = new MenuTabsContainer();
}
