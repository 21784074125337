;(function() {

    window.addEventListener('DOMContentLoaded', function() {

        // TABLES

        var tables = document.querySelectorAll('.wp-block-advgb-table:not(.no-help)');

        tables.forEach(function(table) {
            if (table.closest('.js-table-wrapper')) {
                var label = table.closest('.js-table-wrapper').lastElementChild;
                if (label) {
                    label.innerText = document.myTranslate.tableSwipe;
                }
            } else {
                var wrapper = document.createElement('div');
                var label = document.createElement('p');
                wrapper.classList.add('js-table-wrapper');
                table.parentNode.insertBefore(wrapper, table);
                label.innerText = document.myTranslate.tableSwipe;
                wrapper.appendChild(label);
                wrapper.appendChild(table);
            }
        });

        // SLIDERS

        if (!document.documentElement.classList.contains('export')) {

            var sliders = document.querySelectorAll('.swiper-container .swiper-wrapper');

            sliders.forEach(function(slider) {
                var slides = slider.querySelectorAll('figure');
                slides.forEach(function(slide, index) {
                    var caption = slide.querySelector('figcaption');
                    var pager = document.createElement('span');
                    pager.className = 'slider-pager';
                    pager.innerHTML = (index + 1) + '&nbsp;/&nbsp;' + slides.length;
                    caption.appendChild(pager);
                });
                $(slider).slick({
                    adaptiveWidth: true,
                    adaptiveHeight: true
                });
                $(slider).on('afterChange', function(event, slick, currentSlide) {
                    var tabPanel = slick.$slider[0].closest('.wp-block-ub-tabbed-content-tab-content-wrap');
                    if (tabPanel) {
                        tabPanel.closest('.wp-block-ub-tabbed-content-tabs-content').style.height = calculatePanelHeight(tabPanel) + 'px';
                    }
                });
            });

        }

        // TABS

        if (!document.documentElement.classList.contains('export')) {

            var tabs = document.querySelectorAll('.wp-block-ub-tabbed-content');

            var calculatePanelHeight = function(panel) {
                return panel.offsetHeight;
            }

            var activatePanel = function(label, fromMenu) {
                var labelHolder = label.closest('.wp-block-ub-tabbed-content-tab-holder');
                var panelWrapper = label.closest('.wp-block-ub-tabbed-content').querySelector('.wp-block-ub-tabbed-content-tabs-content');
                var panels = panelWrapper.querySelectorAll('.wp-block-ub-tabbed-content-tab-content-wrap');
                var targetIndex = label.dataset.tabTarget;
                var targetPanel = panels[targetIndex];
                if (fromMenu && labelHolder && !labelHolder.classList.contains('is-active') && window.innerWidth < 1200) {
                    labelHolder.classList.add('is-active');
                    return;
                }
                document.querySelector('.wp-block-ub-tabbed-content-tab-title.is-active').classList.remove('is-active');
                document.querySelector('.wp-block-ub-tabbed-content-tab-content-wrap.is-active').classList.remove('is-active');
                document.querySelector('.wp-block-ub-tabbed-content-tab-title-wrap.active').classList.remove('active');
                targetPanel.classList.add('is-active');
                label.classList.add('is-active');
                label.closest('.wp-block-ub-tabbed-content-tab-title-wrap').classList.add('active');
                panelWrapper.style.height = calculatePanelHeight(targetPanel) + 'px';
                if (fromMenu && labelHolder && labelHolder.classList.contains('is-active')) {
                    labelHolder.classList.remove('is-active');
                }
            }

            if (tabs.length && document.querySelector('.section-article-text')) {
                document.querySelector('.section-article-text').classList.add('has-tabs');
            }

            tabs.forEach(function(tab) {
                var labels = tab.querySelectorAll('.wp-block-ub-tabbed-content-tab-title');
                var panels = tab.querySelectorAll('.wp-block-ub-tabbed-content-tab-content-wrap');
                var panelWrapper = tab.querySelector('.wp-block-ub-tabbed-content-tabs-content');
                var links = panelWrapper.querySelectorAll('a[href^="#"]');
                var activeHash = window.location.hash ? window.location.hash.replace('#', '') : null;
                if (document.querySelector('.wp-block-ub-tabbed-content-tab-title.is-active')) {
                    document.querySelector('.wp-block-ub-tabbed-content-tab-title.is-active').classList.remove('is-active');
                }
                if (document.querySelector('.wp-block-ub-tabbed-content-tab-content-wrap.is-active')) {
                    document.querySelector('.wp-block-ub-tabbed-content-tab-content-wrap.is-active').classList.remove('is-active');
                }
                labels.forEach(function(label, index) {
                    label.dataset.tabTarget = index;
                    var targetIndex = label.dataset.tabTarget;
                    var targetPanel = panels[targetIndex];
                    if (targetPanel.dataset.tabAnchor) {
                        label.dataset.tabAnchor = targetPanel.dataset.tabAnchor;
                    }
                    if (activeHash && label.dataset.tabAnchor && label.dataset.tabAnchor == activeHash) {
                        label.classList.add('is-active');
                        targetPanel.classList.add('is-active');
                        document.querySelector('.wp-block-ub-tabbed-content-tab-title-wrap.active').classList.remove('active');
                        label.closest('.wp-block-ub-tabbed-content-tab-title-wrap').classList.add('active');
                        setTimeout(function() {
                            panelWrapper.style.height = calculatePanelHeight(targetPanel) + 'px';
                        }, 250);
                    } else if (!activeHash && 0 === index) {
                        label.classList.add('is-active');
                        panels[0].classList.add('is-active');
                        panelWrapper.style.height = calculatePanelHeight(panels[0]) + 'px';
                    }
                    label.addEventListener('click', function() {
                        activatePanel(this, true);
                        if (label.dataset.tabAnchor) {
                            window.location.hash = label.dataset.tabAnchor;
                        }
                    });
                });
                links.forEach(function(link) {
                    link.addEventListener('click', function() {
                        var href = this.getAttribute('href').replace('#', '');
                        var label = document.querySelector('[data-tab-anchor="' + href + '"]');
                        if (label) {
                            activatePanel(label, false);
                        }
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                    });
                });
            });

            window.addEventListener('resize', function() {
                tabs.forEach(function(tab) {
                    var panelWrapper = tab.querySelector('.wp-block-ub-tabbed-content-tabs-content');
                    var activePanel = tab.querySelector('.wp-block-ub-tabbed-content-tab-content-wrap.is-active');
                    if (panelWrapper && activePanel) {
                        var height = calculatePanelHeight(activePanel);
                        if (height !== panelWrapper.offsetHeight) {
                            panelWrapper.style.height = height + 'px';
                        }
                    }
                });
            });

        }

    });

})();