var isMobile = detectMobile();

document.addEventListener('click', event => {
    let select = document.querySelectorAll('.js-variants-select.open');
    if (null === event.target.closest('.js-variants-select') && select) {
        select.forEach(element => {
            element.classList.remove('open');
        });
    }
});

document.addEventListener('DOMContentLoaded', () => {

    // Replace placeholder in search input
    if (document.querySelector('#frmsearchForm-searchtext')) {
        document.querySelector('#frmsearchForm-searchtext').value = '';
        document.querySelector('#frmsearchForm-searchtext').placeholder = document.myTranslate.SearchFrm;
    }

    // Add special class if browsing on mobile device
    if (isMobileBrowser()) {
        document.body.classList.add('mobile_browser');
    }

    // Something to do with users and price 
    if (document.querySelector('#hideIfPrccat') && document.querySelector('#prccat') && document.querySelector('#prccat').dataset.prccat == 0) {
        document.querySelector('#hideIfPrccat').classList.remove('hidden');
    }

    // Add classnames to element's children specified in data attribute
    document.querySelectorAll('.js-add-class-child').forEach(element => {
        for (let i = 0; i < element.children.length; i++) {
            element.children[i].classList.add(element.dataset.class);
        }
    });

    // Trigger open Bitrix chat floating window
    document.querySelectorAll('.box-desc__bitrix__chat').forEach(element => {
        element.addEventListener('click', event => {
            event.preventDefault();
            let chatTrigger = document.querySelector('*[data-b24-crm-button-widget="openline_livechat"] .b24-widget-button-social-tooltip');
            if (chatTrigger) {
                chatTrigger.click();
            }
        });
    });

    let postalCodes = ['frmregistrationForm-psc', 'frmformUser-psc', 'frmformUser-del_psc'];

    postalCodes.forEach(code => {
        let input = document.getElementById(code);
        if (input && input.dataset.netteRules && input.dataset.netteRules.indexOf('msg:\'0\'') !== 0) {
            input.dataset.netteRules = input.dataset.netteRules.replace('msg:\'0\'', 'msg:\'' + document.myTranslate.postCodeFormatError + '\'');
        }
    });

    // Toggle submenu open/close
    document.querySelectorAll('.l-m__clicker').forEach(element => {
        element.addEventListener('click', function(event) {
            event.preventDefault();
            event.stopPropagation();
            let items = Array.prototype.slice.call(element.closest('.l-m__i').children).filter(child => {
                return child.classList.contains('l-m__l');
            });
            let link = element.closest('.l-m__i').querySelector('.l-m__lk');
            if (isMobile) {
                if (link.classList.contains('l-m__lk--1')) {
                    document.querySelectorAll('.l-m__lk--1').forEach(el => {
                        if (el !== link) {
                            el.classList.remove('child_show');
                        }
                    });
                    document.querySelectorAll('.l-m__l--sub').forEach(el => {
                        items.forEach(item => {
                            if (el !== item) {
                                el.classList.remove('show');
                            }
                        });
                    });
                } else {
                    document.querySelectorAll('.l-m__lk--2').forEach(el => {
                        if (el !== link) {
                            el.classList.remove('child_show');
                        }
                    });
                    document.querySelectorAll('.l-m__l--sub2').forEach(el => {
                        items.forEach(item => {
                            if (el !== item) {
                                el.classList.remove('show');
                            }
                        });
                    });
                }
            }
            items.forEach(item => {
                if (item.classList.contains('show')) {
                    item.classList.remove('show');
                    link.classList.remove('child_show');
                } else {
                    item.classList.add('show');
                    link.classList.add('child_show');
                }
            });
        });
    });

    document.querySelectorAll('[data-toggle="tooltip"]').forEach(element => {
        $(element).tooltip({
            html: true,
            trigger: 'hover click'
        });
    });

    /*
     * Wait for Bitrix form to appear, then fill input labelled as "Client ID" with
     * Google Analytics 4 cookie value if said cookie is saved.
     */
    var bitrixFormObserver = new MutationObserver(function(mutations) {
        mutations.forEach((mutation) => {
            mutation.addedNodes.forEach(function(node) {
                // We only care about the Bitrix form element
                if (node instanceof Element && node.classList.contains('b24-form')) {
                    // Find label which contains the correct name
                    node.querySelectorAll('.b24-form-control-label').forEach(function(label) {
                        if (label.innerHTML.indexOf('Client ID') !== -1) {
                            var formInput = label.previousElementSibling;
                            var formRow = label.closest('.b24-form-field');
                            formRow.classList.add('hidden');
                            // Iterate over all saved cookies and find the correct one
                            for (const [name, value] of Object.entries($.cookie())) {
                                if (name === '_ga') {
                                    var splitValue = value.split('.');
                                    // Take the two longest numbers and glue them together
                                    var ga4cid = splitValue[2] + '.' + splitValue[3];
                                    formInput.dispatchEvent(new Event('keypress'));
                                    formInput.value = ga4cid;
                                    formInput.dispatchEvent(new Event('input'));
                                }
                            }
                        }
                    });
                    // Stop observing if Bitrix form found to free up resources
                    // Commented out because there could be multiple forms on the page
                    //bitrixFormObserver.disconnect();
                }
            });
        });
    });

    bitrixFormObserver.observe(document.body, {
        childList: true, 
        subtree: true, 
        attributes: false, 
        characterData: false
    });

    var searchToggle = document.querySelector('.js-toggle-search');
    var searchBox = document.querySelector('.js-search');
    var searchInput = document.querySelector('#frmsearchForm-searchtext');

    var localeToggle = document.querySelector('.js-locale-toggle');
    var localeList = document.querySelector('.js-locale-list');

    if (searchToggle) {
        var activeClass = 'is-open';
        searchToggle.addEventListener('click', function(event) {
            event.stopPropagation();
            this.classList.toggle(activeClass);
            searchBox.classList.toggle(activeClass, this.classList.contains(activeClass));
        });
        searchBox.addEventListener('click', function(event) {
            event.stopPropagation();
        });
        searchInput.addEventListener('focus', function(event) {
            event.stopPropagation();
            if (this.value.length > 0) {
                document.querySelector('.box-search').classList.add('js-open');
            }
        });
        window.addEventListener('keyup', function(event) {
            if (event.key === 'Escape') {
                if (searchToggle.classList.contains(activeClass)) {
                    searchToggle.classList.remove(activeClass);
                    searchBox.classList.remove(activeClass);
                }
                if (window.innerWidth > 991) {
                    document.querySelector('.box-search').classList.remove('js-open');
                }
                if (localeToggle) {
                    localeToggle.classList.remove('is-active');
                }
            }
        });
    }

    if (localeToggle) {
        localeToggle.addEventListener('click', function(event) {
            event.stopPropagation();
            this.classList.toggle('is-active');
        });
        localeList.addEventListener('click', function(event) {
            event.stopPropagation();
        });
    }

    var menuSectionToggle = document.querySelector('.js-mobile-menu-section-switch');

    menuSectionToggle.addEventListener('click', function() {
        this.classList.toggle('show-home');
        this.classList.toggle('show-shop');
    });

    var categoryItemToggles = document.querySelectorAll('.js-category-toggle');

    categoryItemToggles.forEach(function(toggle) {
        toggle.addEventListener('click', function() {
            this.closest('li').classList.toggle('is-open');
        });
    });

    document.querySelectorAll('.js-main-menu-item-dropdown').forEach(toggle => {
        toggle.addEventListener('click', function() {
            toggle.classList.toggle('is-open');
        });
    });

    var mobileMenuToggle = document.querySelector('.js-main-menu-toggle');
    var mobileMenu = document.querySelector('.js-mobile-menu');

    if (mobileMenuToggle) {
        mobileMenuToggle.addEventListener('click', function(event) {
            event.stopPropagation();
            this.classList.toggle('is-open');
            mobileMenu.classList.toggle('is-open', this.classList.contains('is-open'));
        });
        mobileMenu.addEventListener('click', function(event) {
            event.stopPropagation();
        });
    }

    document.addEventListener('click', function() {
        searchToggle.classList.remove(activeClass);
        searchBox.classList.remove(activeClass);
        if (window.innerWidth > 991) {
            document.querySelector('.box-search').classList.remove('js-open');
        }
        if (localeToggle) {
            localeToggle.classList.remove('is-active');
        }
        mobileMenuToggle.classList.remove('is-open');
        mobileMenu.classList.remove('is-open');
    });

    var categorySliders = document.querySelectorAll('.js-category-slider .splide');

    categorySliders.forEach(splider => {
        var slider = new Splide(splider, {
            type: 'loop',
            perPage: splider.closest('.js-category-slider').classList.contains('category-slider--big') ? 4 : 2,
            perMove: splider.closest('.js-category-slider').classList.contains('category-slider--big') ? 4 : 2,
            gap: '15px',
            breakpoints: {
                768: {
                    perPage: 2,
                    perMove: 2,
                }
            }
        });
        slider.mount();
    });

    if (document.documentElement.classList.contains('export')) {

        var detailElements = document.querySelectorAll('details');

        detailElements.forEach(element => {
            element.setAttribute('open', '');
        });

    }

    if ($(window).width() > 768) {
        $('.p-icewarp-page .splide__slide:not(.splide__slide--clone):not(.s-customer-testimonial)').each(function (index) {
            var alternateTitle = $(this).find('img').attr('alt');
            if (typeof $(this).find('img').attr('alt') != 'undefined'
                && $(this).find('img').attr('alt') !== '')
            {
                $('.splide__pagination__page').eq(index).text(alternateTitle);
            } else {
                $('.splide__pagination__page').eq(index).text('#' + index);
            }

            $('.splide__pagination__page button').css('display', 'contents !important');
        });
    }

});
